export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';
export const apiUrlBase = `https://be.eseva.cmccuttack.teceads.co.in/`
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';
export const servicePathBookingOldUrl = 'https://bookings.berhampur.gov.in';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const apiUrl = `https://be.eseva.cmccuttack.teceads.co.in/api/v1/`;
export const staticFileUrl = `https://be.eseva.cmccuttack.teceads.co.in/`;

// export const apiUrl = `http://localhost:8000/api/v1/`;
// export const staticFileUrl = `http://localhost:8000/`;

// export const apiUrl = `http://192.168.1.17:8000/api/v1/`;
// export const staticFileUrl = `http://192.168.1.17:8000/`;

export const apiSecret = `registeruser`;

export const getToken = async () => {
  let token = await localStorage.getItem('data');
  token = token ? JSON.parse(token).token : '';
  return `${token}`;
};
export const getRole = async () => {
  let role = await localStorage.getItem('data');
  role = role ? JSON.parse(role).role : '';
  return `${role}`;
};
export const getBasicUserDetails = async () => {
  let role = await localStorage.getItem('data');
  role = role ? JSON.parse(role) : {};
  console.log(role, 'role1');

  if (role && role != '' && role != {}) {
    role = role.userBasicDetails;
    console.log(role, 'role2');
  }
  return role;
};
